// const stagingFirebase = {
//   apiKey: 'AIzaSyAWww6XagNc5SmbqKQAbZ6ynkF33JD_2is',
//   authDomain: 'go-care-3a501.firebaseapp.com',
//   projectId: 'go-care-3a501',
//   storageBucket: 'go-care-3a501.appspot.com',
//   messagingSenderId: '623366582503',
//   appId: '1:623366582503:web:ea266395fdd50cd2bc3242',
//   measurementId: 'G-0GWZE0JE3J',
//   keyPair:
//     'BH1gMcA2zoE7XuBsarPdljvnYtsbalAPmgH-jyhmu0X7OKjcSbw6kHlUf4cvNw3PRLcDHv0lHK1lvxR81roOsrg',
//   serverKey:
//     'key=AAAAkSOLMOc:APA91bGIBkS_rKynzbvqDaOo1Vu8h-6JpUzlJwfyVRIaujT0P26X0fVuE9Q1x4AJD8J79mEZB7I-_9xTZhxEu2PKuRuNMOuhfLqblWtwKae4PrCHbfASFW9OKLPbJO1RmYXKkTM8MxJ4',
// }
const stagingFirebase = {
	apiKey: 'AIzaSyBBrb8OU_rnndP2ucgaKZTYzZRgAj8_uTo',
	authDomain: 'go-care-for-business.firebaseapp.com',
	projectId: 'go-care-for-business',
	storageBucket: 'go-care-for-business.appspot.com',
	messagingSenderId: '853224797328',
	appId: '1:853224797328:web:10df9dffd483a57505d415',
	measurementId: 'G-Z3G087MTG7',
	keyPair:
		'BF7_a9d-8cSpcEvaYAw5n-ZpN6QpdyN5HNAys7nkrS4RljQ98r9gETkcNSRGZD9QN0SB4f3zqnkFCcvpvBWZRA0',
	serverKey:
		'key=AAAAxqgpNJA:APA91bE4Wof4u5-CM_tkkukU_p7ZlakEe4pq4xxISyM23dQGsafZkJJYpMfIJYGzFoD7mvrxLtdbKWzXErvEv2PFhbvLcBHv2aKNFV2GEEbAAHEjJQhPdlUXwAeRv7QV4EVZJpdynmbi',
}

export const environment = {
	production: true,
	baseURL: 'https://gocare-back-stage.go-care.com',
	apiUrl: 'https://gocare-back-stage.go-care.com/api',
	attachmentPath: 'https://gocare-back-stage.go-care.com/Attachments',
	firebaseConfig: { ...stagingFirebase },
	googleMapKey: 'AIzaSyA7GuhrFYR_k6lQ1JQvClXX9SEdyg9BpvA',
}
